<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP JOB SITE EDIT

type    : view

uses    : simple-dialog-template
          address-validation-map
          header-view
          main-container

route   : /jobSites/edit/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-job-site-edit">
    <header-view
      title="Edit Job Site"
      :closeAction="
        () => {
          $router.go(-1);
        }
      "
      :previous_page="
        '/clients/view/' + this.jobSite.client_uuid + '?tab=jobSites'
      "
      :btnOptions="[
        {
          name: 'Save',
          action: handleSubmit,
          btnColor: 'button-primary',
        },
      ]"></header-view>
    <main-container>
      <v-form
        ref="form"
        @submit.prevent="handleSubmit"
        class="mt-2"
        id="jobSiteForm">
        <v-container>
          <v-text-field
            id="job_site_name"
            v-model="jobSite.name"
            label="Job Site Name" />

          <!-- address search and display map -->
          <!-- current address assigned to jobsite being edited will be passed in via addressSearch -->
          <address-validation-map
            v-on:jobSiteValidated="updateJobSiteAddress"
            :addressSearch="jobSiteAddressString"
            :rules="validate_address" />

          <simple-dialog-template
            :open="errorDialog"
            dialogTitle="Error Creating New Service Request"
            :dialogText="errorMessage"
            dialogButtonOne="OK"
            @buttonOne="errorDialog = false" />
        </v-container>
      </v-form>
    </main-container>
  </div>
</template>

<script>
  // components
  import MainContainer from '@/components/main-container';
  import HeaderView from '@/components/header-view';
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import AddressValidationMap from '@/components/address-validation-map';

  // mixins
  import addresses from '@/mixins/addresses';
  import Forms from '@/mixins/forms.js';

  // services
  import Clients from '@/services/Clients.service.js';

  export default {
    name: 'AppJobSiteEdit',
    components: {
      'simple-dialog-template': SimpleDialogTemplate,
      'address-validation-map': AddressValidationMap,
      'header-view': HeaderView,
      'main-container': MainContainer,
    },
    mixins: [addresses, Forms],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        errorMessage: '',
        errorDialog: false,
        loader: false,
        loading4: false,
        jobSite: {
          uuid: '',
          client_uuid: '',
          name: '',
          address: '',
          city: '',
          state: '',
          zip_code: '',
          country: '',
        },
        submitted: false,
        jobSiteAddressString: '',
      };
    },
    computed: {},
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];

        setTimeout(() => (this[l] = false), 3000);

        this.loader = null;
      },
    },
    async created() {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // get current job site details for editing
      this.jobSite = await Clients.getJobSite(this.uuid, accessToken);

      // set current jobsite address string for address-validation-map
      if (this.jobSite) {
        this.jobSiteAddressString = this.addressToString(this.jobSite);
      }
    },
    methods: {
      updateJobSiteAddress: function (newAddress) {
        if (newAddress) {
          this.jobSite.address = newAddress.address;
          this.jobSite.city = newAddress.city;
          this.jobSite.state = newAddress.state;
          this.jobSite.zip_code = newAddress.zip_code;
          this.jobSite.country = newAddress.country;
        }
      },
      async handleSubmit() {
        // validate form
        if (!this.$refs.form.validate()) {
          console.log('invalid form entries!');
          return false;
        }

        // make sure none of the job site requirements are empty
        if (
          !(
            this.jobSite.address &&
            this.jobSite.city &&
            this.jobSite.state &&
            this.jobSite.zip_code &&
            this.jobSite.country
          )
        ) {
          this.errorMessage = 'Job Site Address Not Valid';
          this.errorDialog = true;
          return;
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // create updated job site data object
        const updatedJobSite = {
          name: this.jobSite.name,
          uuid: this.jobSite.uuid,
          client_id: this.jobSite.client_uuid,
          address: this.jobSite.address,
          city: this.jobSite.city,
          state: this.jobSite.state,
          zip_code: this.jobSite.zip_code,
          country: this.jobSite.country,
        };

        // send request to the api service
        const res = await Clients.updateJobSite(
          this.uuid,
          updatedJobSite,
          accessToken
        );

        // route back to client job site view or print error
        if (res) {
          this.$router.push(
            '/clients/view/' + this.jobSite.client_uuid + '?tab=jobSites'
          );
        } else {
          this.errorMessage = 'Error updating job site';
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
